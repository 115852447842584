<template>
  <layout-level2
    class="view-loyalty-config"  
    v-bind="attrs"
    v-on="listeners">
    <router-view 
      :loading="loading" 
      :errors="errors"
      :read-only="isReadOnly"
      :dirty-vouchers="dirtyVouchers"
      @icon="onIcon"
      @logo="onLogo"
      @strip="onStrip"
      v-if="!loading" 
    />
  </layout-level2>
</template>

<script>
import { 
  mapGetters, 
  mapState,
} from "vuex"

import { 
  MixinACL, 
  MixinEditionRemove, 
  MixinEditionDiscard, 
  MixinEditionSave, 
  MixinError, 
  MixinImage,
  MixinImpersonation,
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyEditModal',

  mixins: [ 
    MixinACL, 
    MixinEditionRemove, 
    MixinEditionDiscard, 
    MixinEditionSave, 
    MixinError,
    MixinImage,
    MixinImpersonation,
  ],

  data() {
    return {
      loading: true,
      errors: {},

      strip: null,
      logo: null,
      imgIcon: null,

      backTab: null,

      dirtyVouchers: []
    } 
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
      referral: state => state.loyalty.referral.edition,

      module: state => state.ginger.module,
      programs: state => state.loyalty.loyalty.collection,
      state: state => state.loyalty.loyalty,
    }),

    ...mapGetters({
      pristineEdition: 'loyalty/loyalty.pristine',
      pristineReferral: 'loyalty/referral.pristine',
    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        entity: this.$t('sayl-ecommerce.category'),
        icon: 'cog',
        hasSaveButton: this.isEditable && !this.isReadOnly,
        inheritedFromChild: false,
        inheritedFromParent: !this.isMaster || this.isImpersonating,
        pristine: this.pristine,
        loading: this.loading,
        tertiary: this.popinsOptions,
        saving: this.saving,
        tabs: this.tabs,
        title: this.title,
        size: 'm',
      }
    }, 

    breadcrumb() {
      return [
        { label: this.$t('sayl-ecommerce.loyalty'), icon: this.icon, href: { name:'sayl-customer-loyalty_home'} },
        { label: this.title }
      ]
    },

    customerId() {
      return this.$basil.get(this.edition, 'customerId')
    },

    icon() {
      return 'award'
    },

    isEditable() {
      let ret = this.canEdit;

      return ret
    },

    isInherited() {
      return !this.isCurrentCustomer(this.customerId)
    },

    isReadOnly() {
      return !this.isMaster || this.isImpersonating
    },

    listeners() {
      return {
        back: this.onDiscard,
        discard: this.onDiscard,
        close: this.onDiscard,
        save: this.onConfirm,
      }
    },

    popinsOptions() {
      let ret = []

      if(this.canEdit && !this.isReadOnly) { 
        ret.push({ 
          label: this.$t('sayl.delete'), 
          event: { click: this.onRemove } 
        })

        if(this.$route.name === 'sayl-customer-loyalty_program-page') {
          ret.push({
            label: this.$t('sayl-loyalty.loyalty_page_reset_default_value'),
            event: {
              click: this.onResetLoyaltyPage
            }
          })
        }
      }

      return ret
    },

    pristine() {
      return this.pristineEdition && 
        this.$basil.isNil(this.strip) &&
        this.$basil.isNil(this.logo) && 
        this.$basil.isNil(this.imgIcon) && 
        this.pristineReferral
    },

    tabs() {
      return {
        size: this.$pepper.Size.S,
        children: [
          { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_parameters_label'), 
            value: 'program', 
            href: { name: 'sayl-customer-loyalty_program-program' },
          },
          { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_page_label'), 
            value: 'program', 
            href: { name: 'sayl-customer-loyalty_program-page' },
          },
          !window.$dl.elements.isHidden('sayl_resto_url') ? { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_tiers_label'), 
            value: 'tiers', 
            href: { name: 'sayl-customer-loyalty_program-tiers' },
          } : null,
          { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_leaderboard_label'), 
            value: 'leaderboard', 
            href: { name: 'sayl-customer-loyalty_program-leaderboard' },
          },
          this.$basil.get(this.edition, 'allowDigitalCards', true) ? { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_digital_pass_label'),
            value: 'digital_pass', 
            href: { name: 'sayl-customer-loyalty_program-passes' },
          } : null,
          // !window.$dl.fields.isPropHidden('loyalty_program', 'placeholders') ? { 
          //   label: this.$t('sayl-ecommerce.loyalty_config_tab_resto_label'),
          //   value: 'resto', 
          //   href: { name: 'sayl-customer-loyalty_program-resto' },
          // } : null,
          this.$basil.get(this.edition, 'rewardEatin', true) ? { 
            label: this.$t('sayl-ecommerce.loyalty_config_tab_ssm_label'),
            value: 'ssm', 
            href: { name: 'sayl-customer-loyalty_program-ssm' },
          } : null,
        ].filter(r => !this.$basil.isNil(r))
      }
    },

    title() {
      return !this.loading ? 
        basil.get(this.origin, 'name.all', null) : 
        this.$t('sayl.loading')
    }
  },

  methods: {
    back() {
      this.$router.push({ name: 'sayl-customer-loyalty_overview' })
        .catch((e) => {})
    },

    onConfirm() {
      this.loading = true
      this.onSave({})
        .then(() => this.reset())
        .catch((e) => {
          $console.error(e)
          this.loading = false
        })
        .finally(() => this.backTab = this.tab)
    },

    onIcon(payload) {
      this.imgIcon = payload
    },

    onLogo(payload) {
      this.logo = payload
    },

    onResetLoyaltyPage() {
      this.$confirm({
        primary: this.$t('sayl.confirm'),
        secondary: this.$t('sayl.cancel'),
        title: this.$t('sayl-loyalty.loyalty_page_reset_confirm_title'),
        description: this.$t('sayl-loyalty.loyalty_page_reset_confirm_description'),
        onPrimary: () => {
          window.$dl.loyalty.resetPage(this.edition, this.$t.bind(this))
            .then(() => this.$bus.$emit('loyalty-page-reset'))
            .catch((e) => console.error(e))
        }
      })
    },

    onStrip(payload) {
      this.strip = payload
    },

    onTab(tab) {
      this.tab = tab.value
    },

    remove(payload) {
      return new Promise((resolve, reject) => {
        this.state.invalid = true;
        this.$basil.sequence([
          window.$dl.loyalty.remove.bind(window.$dl.loyalty, payload),
          window.$dl.loyalty.collection.bind(window.$dl.loyalty, {})
        ])
          .then(() => resolve())
          .catch((e) => reject(e))
      })
    },

    reset() {
      this.loading = true
      this.dirtyVouchers = []

      this.logo = null
      this.imgIcon = null
      this.strip = null

      Promise.all([
        window.$dl.loyalty.findById({ id: this.$route.params.program, args: { translator: this.$t.bind(this) } }),
        window.$dl.loyalty.getShops({}),
        this.$ecommerce.voucherTemplates.all({ args: { isTemplate: 1 }}),
        this.$image.all({ data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'mail_logo', module: 'loyalty' }}),
      ])
        .then(() => {
          let tab = this.$basil.get(this.$route, 'query.tab', null)
          tab = this.$basil.isNil(tab) ? this.backTab : tab

          this.tab = this.$basil.isNil(tab) ? 'program' : tab
          this.$router.replace({ name: this.$route.name, params: this.$route.params }).catch(() => {})
          this.backTab = null
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    save() {
      this.errors = {}

      let proms = [
        !this.$basil.isNil(this.strip) ? this.saveImages.bind(this, this.strip) : null,
        !this.$basil.isNil(this.logo) ? this.saveImages.bind(this, this.logo) : null,
        !this.$basil.isNil(this.imgIcon) ? this.saveImages.bind(this, this.imgIcon) : null,
      ]

      if(!this.pristineReferral) {
        proms.push(window.$dl.referral.save.bind(window.$dl.referral, { item: this.referral.toAPI(), id: this.referral.id }))
      }

      this.dirtyVouchers.forEach(v => {
        let index = this.edition.redeemableVouchers.indexOf(this.edition.redeemableVouchers.find(rv => rv.voucher_id === v))
        if(index > -1) {
          this.edition.redeemableVouchers.splice(index, 1)
        }
      })

      proms = proms.filter(p => !this.$basil.isNil(p))
      return new Promise((resolve, reject) => {
        this.$basil.sequence(proms)
          .then(() => window.$dl.loyalty.save({ item: this.edition, isNew: false }))
          .then((res) => resolve(res))
          .catch((e) => reject(e))
          .finally(() => window.$dl.loyalty.collection({}))
      })
    }
  },

  mounted() {
    this.reset()
  },
}
</script>
