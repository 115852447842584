<template>  
    <div 
      style="display: contents;">
      <div class="layout-level2__container container -cols">
        <div class="col">
          <ui-card 
            :appearance="$pepper.Appearance.SUBTLE" 
            article>
            <template #header>
              <h3 class="ui-card__title">{{ $t('sayl-loyalty.leaderboard_settings_title') }}</h3>
            </template>

            <!-- Leaderboard Activation -->
            <article class="ui-card__article">
              <forms-checkbox
                :appearance="$pepper.Appearance.TOGGLE"
                :hint="$t('sayl-loyalty.leaderboard_active_hint')"
                :read-only="readOnly"
                :size="$pepper.Size.S"
                v-model="edition.leaderboardActive"
              >{{ $t('sayl-loyalty.leaderboard_active_label') }}</forms-checkbox>
            </article>

            <!-- Leaderboard Podium Size -->
            <article class="ui-card__article">
              <forms-input
                @input="onRemoveError('leaderboard_podium_size')"
                :errors="getErrors('leaderboard_podium_size')"
                :hint="$t('sayl-loyalty.loyalty_config_leaderboard_podium_size_hint')"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                min="1"
                max="10"
                step="1"
                type="number"
                v-model="edition.leaderboardPodiumSize">
                {{ $t('sayl-loyalty.loyalty_config_leaderboard_podium_size_label') }}
              </forms-input>
            </article>

            <!-- Leaderboard Page Size -->
            <article class="ui-card__article">
              <forms-input
                @input="onRemoveError('leaderboard_page_size')"
                :errors="getErrors('leaderboard_page_size')"
                :hint="$t('sayl-loyalty.loyalty_config_leaderboard_page_size_hint')"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                min="1"
                step="1"
                type="number"
                v-model="edition.leaderboardPageSize">
                {{ $t('sayl-loyalty.loyalty_config_leaderboard_page_size_label') }}
              </forms-input>
            </article>
          </ui-card>
        </div>
  
        <div class="col">
          <leaderboard-url/>

          <ui-card 
            :appearance="$pepper.Appearance.SUBTLE"
            article>
            <template v-slot:header>
              <h3 class="ui-card__title">{{ $t('sayl-loyalty.loyalty_config_leaderboard_banner_title') }}</h3>
            </template>

            <!-- Leaderboard Banner -->

            <article 
              class="ui-card__article flow">
              <div>
                <forms-image-input
                  accept="image/png,image/jpg"
                  :entity-id="$route.params.program"
                  entity-type="loyalty_program"
                  :errors="errorsImg['banner']"
                  field-name="leaderboard_banner"
                  module="loyalty"
                  :read-only="readOnly"
                  required
                  :title="$t('sayl-loyalty.loyalty_config_leaderboard_banner_title')"
                  :loading="loadingImages"
                  @clear="clearImages"
                  @change="saveImages"
                />
                <div class="view-loyalty-config__hint">{{ $t('sayl-loyalty.loyalty_config_leaderboard_banner_hint') }}</div>
              </div>
            </article>
          </ui-card>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'

  import {
    MixinError,
    MixinImage
  } from '@sayl/admin-common'

  import LeaderboardUrl from './url.vue'
  
  export default {
    name: 'LoyaltyProgramLeaderboard',
  
    components: {
      LeaderboardUrl
    },
  
    mixins: [ 
      MixinError,
      MixinImage
    ],
  
    props: {
      errors: {
        type: Object,
        default: {},
      },
      readOnly: {
        type: Boolean, 
        default: false,
      }
    },

    data() {
      return {
        loading: false,
        errorsImg: {}
      }
    },
  
    computed: {
      ...mapState({
        edition: state => state.loyalty.loyalty.edition,
      })
    },
  
    methods: {  
      reset() {
        this.loading = true
        const bound = this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'leaderboard_banner', module: 'loyalty' }})
        Promise.resolve(undefined)
          .then(bound)
          .then(() => {
            this.$configuration.userPortal.all({ args: { product: 'resto',  type: 'user_portal' }})
          })
          .catch($console.error)
          .finally(() => this.loading = false)
      }
    },

    mounted() {
      this.reset()
    }
  }
  </script>