import Index from '@/views/loyalty/index.vue/'

import Overview from '@/views/loyalty/overview/index.vue'
import Cards from '@/views/loyalty/cards/index.vue'

import Referral from '@/views/loyalty/referrals/index.vue'
import ReferralEdition from '@/views/loyalty/referrals/item/index.vue'

import Config from '@/views/loyalty/configuration/router.vue'
import Program from '@/views/loyalty/configuration/edit.vue'
import ProgramEdition from '@/views/loyalty/configuration/program/index.vue'
import ProgramLeaderboard from '@/views/loyalty/configuration/leaderboard/index.vue'
import ProgramPage from '@/views/loyalty/configuration/page/index.vue'
import ProgramPasses from '@/views/loyalty/configuration/passes/index.vue'
import ProgramResto from '@/views/loyalty/configuration/resto/index.vue'
import ProgramSsm from '@/views/loyalty/configuration/ssm/index.vue'
import ProgramTier from '@/views/loyalty/configuration/tiers/item/index.vue'
import ProgramTiers from '@/views/loyalty/configuration/tiers/index.vue'

const root = '/loyalty'

let routes = [
  {
    name: 'sayl-customer-loyalty_home',
    path: root,
    component: Index,
    redirect: { name: 'sayl-customer-loyalty_overview' },
    meta: {
      bodyClass: 'view-loyalty',
    },
    children: [
      {
        name: 'sayl-customer-loyalty_cards',
        path: 'cards',
        component: Cards,
        meta: {
          resource: 'loyalty.program',
        }
      },
      {
        name: 'sayl-customer-loyalty_overview',
        path: 'overview',
        component: Overview,
        meta: {
          bodyClass: 'view-loyalty-overview',
          resource: 'loyalty.program',
        },
      },
      {
        name: 'sayl-customer-loyalty_referral',
        path: 'referral',
        component: Referral,
        meta: {
          resource: 'loyalty.referral',
        },
      },
    ]
  },

  {
    name: 'sayl-customer-loyalty_referral-edition',
    path: `${root}/referral/config`,
    component: ReferralEdition,
    meta: {
      bodyClass: 'view-referral',
      resource: 'loyalty.referral',
    },
  },


  {
    name: 'sayl-customer-loyalty_program-config',
    path: `${root}/config`,
    component: Config,
  },

  {
    name: 'sayl-customer-loyalty_program',
    path: `${root}/:program`,
    component: Program,
    redirect: { name: 'sayl-customer-loyalty_program-program' },
    meta: {
      resource: 'loyalty.program',
    },
    children: [
      {
        name: 'sayl-customer-loyalty_program-passes',
        path: 'passes',
        component: ProgramPasses,
        meta: {
          resource: 'loyalty.program',
          feature: 'pass',
        }
      },
      {
        name: 'sayl-customer-loyalty_program-program',
        path: 'program',
        component: ProgramEdition,
      },
      {
        name: 'sayl-customer-loyalty_program-resto',
        path: 'resto',
        component: ProgramResto,
      },
      {
        name: 'sayl-customer-loyalty_program-tiers',
        path: 'tiers',
        component: ProgramTiers,
        children: [
          {
            name: 'sayl-customer-loyalty_program-tier',
            path: ':tier',
            component: ProgramTier,
          }
        ],
        meta: {
          resource: 'loyalty.program',
          feature: 'tiers',
        }
      },
      {
        name: 'sayl-customer-loyalty_program-ssm',
        path: 'ssm',
        component: ProgramSsm,
      },
      {
        name: 'sayl-customer-loyalty_program-page',
        path: 'page',
        component: ProgramPage,
      },
      {
        name: 'sayl-customer-loyalty_program-leaderboard',
        path: 'leaderboard',
        component: ProgramLeaderboard
      }
    ]
  }
]

export default routes