<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE"
    article
    class="modal-leaderboard-url">
    <template #header>
      <h3 class="ui-card__title">{{ $t('sayl-loyalty.leaderboard_get_url_modal_title') }}</h3>
    </template>

    <article class="ui-card__article group modal-leaderboard-url__actions">
      <forms-input
        id="leaderboard-url"
        read-only
        :value="url"
      >{{ $t('sayl-loyalty.leaderboard_get_url_label') }}</forms-input>

      <actions-button
        icon-post="copy"
        @click="onCopy"
      />

      <actions-button
        icon-post="open"
        @click="onOpen"
      />
    </article>

    <article class="ui-card__article modal-leaderboard-url__qr">
      <img 
        alt="qr_leaderboard"
        :src="qr" 
      />

      <actions-button
        @click="onDownload"
      >{{ $t('sayl-loyalty.leaderboard_download_qr_action') }}</actions-button>
    </article>
  </ui-card>
</template>

<script>
import { 
  mapGetters,
  mapState,
} from 'vuex'

export default {
  name: 'LeaderboardUrl',

  mixins: [
    
  ],

  props: {
    
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState({
      userPortals: state => state.configuration.userPortal.all
    }),

    ...mapGetters({
      config: 'sayl/config'
    }),

    qr() {
      return `${this.$basil.get(this.config, 'image.url')}qr?text=${this.url}&size=300&margin=0`
    }, 

    url() {
      const baseUrl = this.userPortalDomain !== null ?
        `https://${this.userPortalDomain}` :
        `${this.$basil.get(this.config, 'userPortal.url', 'https://apps.sayl.cloud/user/')}${this.userPortalId}`
      return `${baseUrl}/leaderboard`
    },

    userPortalDomain() {
      return this.$basil.get(this.userPortals, '[0].embedDomain', null)
    },

    userPortalId() {
      return this.$basil.get(this.userPortals, '[0].id', null)
    },
  },

  methods: {
    onCopy() {
      let copyText = document.getElementById('leaderboard-url')
      copyText.disabled = false
      copyText.select()
      document.execCommand("copy")
      copyText.disabled = true

      this.$notification({
        title: this.$t('sayl.copy_to_clipboard'), 
        message: this.$t('sayl.copy_to_clipboard_message'), 
        type: 'success'
      })
    },

    async onDownload() {
      const image = await fetch(this.qr)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'leaderboard.png';
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    onOpen() {
      window.open(this.url, '_blank')
    }
  }
}
</script>
