import { Store, StoresStates } from '@spices/basil-sayl'

export default Store('loyalty', {
  fqn: 'loyalty',
  
  getters: {
    pristine: [
      'loyalty',
      'referral'
    ]
  },

  state:  [
    {
      fqn: 'loyalty',
      options: { kpis: true },
      builder: StoresStates.DEFAULT_STATE,
      items: [
        {
          fqn: 'card',
          options: { collection: true, pagination: true, search: true, sort: true, kpis: true },
          builder: StoresStates.DEFAULT_STATE,
          items: [
            {
              fqn: 'users',
              options: { collection: true, pagination: true, search: true },
              builder: StoresStates.DEFAULT_STATE,
            },
          ]
        },
        {
          fqn: 'history',
          options: { collection: true, pagination: true },
          builder: StoresStates.STATE
        },
        {
          fqn: 'shops',
          builder: StoresStates.ALL_STATE
        },
        {
          fqn: 'banner',
          builder: StoresStates.IMAGE_STATE
        }
      ],
    },
    {
      fqn: 'loyalty_program',
      builder: StoresStates.DEFAULT_STATE,
      items: [
        {
          fqn: 'banner',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'leaderboard_banner',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'pass_icon',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'pass_logo',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'pass_strip',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'mail_logo',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_header',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_0',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_1',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_2',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_3',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_4',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_5',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_6',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_7',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_8',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_9',
          builder: StoresStates.IMAGE_STATE
        },
        {
          fqn: 'page_ways_10',
          builder: StoresStates.IMAGE_STATE
        },
      ],
    },
    {
      fqn: 'referral',
      options: { kpis: true },
      builder: StoresStates.DEFAULT_STATE,
      items: [
        {
          fqn: 'nfts',
          options: { edition: true },
          builder: StoresStates.ALL_STATE
        },
        {
          fqn: 'history',
          options: { collection: true, pagination: true },
          builder: StoresStates.STATE,
        }
      ]
    }
  ]
})
